<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">Datos del consecutivo</h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos del consecutivo
                                </div>
                            </div>
                        </div>
                        <b-row v-hotkey="keymap">
                            <b-col cols="12" md="6">
                                <b-form-group>
                                    <label for="clase_documento_id"
                                        >Clase de documento
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <!-- @change="onChangeClaseDocumento" -->
                                    <jautocomplete
                                        id="clase_documento_id"
                                        :items="clasesDocumento"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        v-model="
                                            $v.consecutivo.clase_documento_id
                                                .$model
                                        "
                                        :state="
                                            validateState('clase_documento_id')
                                        "
                                        :disabled="read_only"
                                    />

                                    <!-- <b-form-select
                                        id="clase_documento_id"
                                        :options="clasesDocumento"
                                        text-field="descripcion"
                                        value-field="id"
                                        v-model="
                                            $v.consecutivo.clase_documento_id
                                                .$model
                                        "
                                        :state="
                                            validateState('clase_documento_id')
                                        "
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">
                                                Seleccione...
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.consecutivo.clase_documento_id
                                                .$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <!-- <b-col
                                cols="12"
                                md="3"
                                lg="3"
                                xl="3"
                                v-if="tipoModalidad"
                            >
                                <b-form-group>
                                    <label for="modalidad_facturacion_id"
                                        >Modalidad de facturación
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-select
                                        id="modalidad_facturacion_id"
                                        :options="modalidadesFacturacion"
                                        text-field="descripcion"
                                        value-field="id"
                                        @change="onChangeModalidadFacturacion"
                                        v-model="
                                            $v.consecutivo
                                                .modalidad_facturacion_id.$model
                                        "
                                        :state="
                                            validateState(
                                                'modalidad_facturacion_id'
                                            )
                                        "
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">
                                                Seleccione...
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo
                                                .modalidad_facturacion_id
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col> -->
                            <b-col cols="12" md="6">
                                <b-form-group>
                                    <label for="descripcion">Descripción</label>
                                    <b-form-input
                                        id="descripcion"
                                        type="text"
                                        placeholder="Ej: Consecutivo de factura de venta"
                                        v-model="
                                            $v.consecutivo.descripcion.$model
                                        "
                                        :state="validateState('descripcion')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.consecutivo.descripcion
                                                .$anyError
                                        "
                                    >
                                        ({{
                                            $v.consecutivo.descripcion.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.consecutivo.descripcion.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo.descripcion
                                                .maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.consecutivo.descripcion.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo.descripcion.required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="prefijo">Prefijo</label>
                                    <b-form-input
                                        id="prefijo"
                                        type="text"
                                        placeholder="Ej: FV"
                                        v-model="$v.consecutivo.prefijo.$model"
                                        :state="validateState('prefijo')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <!-- <div
                                        class="length-field mt-1"
                                        v-if="!$v.consecutivo.prefijo.$anyError"
                                    >
                                        ({{
                                            $v.consecutivo.prefijo.$model.length
                                        }}
                                        /
                                        {{
                                            $v.consecutivo.prefijo.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.consecutivo.prefijo.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.consecutivo.prefijo.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback> -->
                                    <!-- <b-form-invalid-feedback
                                        v-if="!$v.consecutivo.prefijo.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback> -->
                                </b-form-group>
                            </b-col>
                            <!-- <b-col v-if="tipoSolicitudYResolucion" cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="tipo_solicitud_numeracion_id"
                                        >Tipo solicitud
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-select
                                        id="tipo_solicitud_numeracion_id"
                                        :options="tiposSolicitud"
                                        text-field="descripcion"
                                        value-field="id"
                                        v-model="
                                            $v.consecutivo
                                                .tipo_solicitud_numeracion_id
                                                .$model
                                        "
                                        :state="
                                            validateState(
                                                'tipo_solicitud_numeracion_id'
                                            )
                                        "
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">
                                                Seleccione...
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo
                                                .tipo_solicitud_numeracion_id
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col> -->
                            <!-- <b-col
                                cols="12"
                                md="7"
                                lg="7"
                                xl="7"
                                v-if="tipoSolicitudYResolucion"
                            >
                                <b-form-group>
                                    <label for="resolucion">Resolución</label>
                                    <b-form-input
                                        id="resolucion"
                                        type="text"
                                        placeholder="Ej: FV"
                                        v-model="
                                            $v.consecutivo.resolucion.$model
                                        "
                                        :state="validateState('resolucion')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.consecutivo.resolucion.$anyError
                                        "
                                    >
                                        ({{
                                            $v.consecutivo.resolucion.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.consecutivo.resolucion.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo.resolucion.maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.consecutivo.resolucion.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo.resolucion.required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col> -->
                        </b-row>
                        <!-- <b-row class="mt-md-4" v-if="tipoSolicitudYResolucion">
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="fecha_ini_resolucion"
                                        >Fecha inicio resolución</label
                                    >
                                    <b-input-group>
                                        <b-form-input
                                            id="fecha_ini_resolucion"
                                            v-model="
                                                $v.consecutivo
                                                    .fecha_ini_resolucion.$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            :state="
                                                validateState(
                                                    'fecha_ini_resolucion'
                                                )
                                            "
                                            autocomplete="null"
                                            :disabled="read_only"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                v-model="
                                                    $v.consecutivo
                                                        .fecha_ini_resolucion
                                                        .$model
                                                "
                                                button-only
                                                right
                                                locale="en-US"
                                                :state="
                                                    validateState(
                                                        'fecha_ini_resolucion'
                                                    )
                                                "
                                                aria-controls="fecha_ini_resolucion"
                                                :disabled="read_only"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.consecutivo.fecha_ini_resolucion
                                                .formatDate
                                        "
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.consecutivo.fecha_ini_resolucion
                                                .compareDates
                                        "
                                    >
                                        La fecha inicial no puede ser mayor a la
                                        fecha final
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.consecutivo.fecha_ini_resolucion
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="fecha_fin_resolucion"
                                        >Fecha final resolución</label
                                    >
                                    <b-input-group>
                                        <b-form-input
                                            id="fecha_fin_resolucion"
                                            v-model="
                                                $v.consecutivo
                                                    .fecha_fin_resolucion.$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            :state="
                                                validateState(
                                                    'fecha_fin_resolucion'
                                                )
                                            "
                                            autocomplete="null"
                                            :disabled="read_only"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                v-model="
                                                    $v.consecutivo
                                                        .fecha_fin_resolucion
                                                        .$model
                                                "
                                                button-only
                                                right
                                                locale="en-US"
                                                :state="
                                                    validateState(
                                                        'fecha_fin_resolucion'
                                                    )
                                                "
                                                aria-controls="fecha_fin_resolucion"
                                                :disabled="read_only"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.consecutivo.fecha_fin_resolucion
                                                .formatDate
                                        "
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.consecutivo.fecha_fin_resolucion
                                                .compareDates
                                        "
                                    >
                                        La fecha final no puede ser menor a la
                                        fecha inicial
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.consecutivo.fecha_fin_resolucion
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row> -->
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="8" lg="8" xl="8">
                                <b-form-group>
                                    <label for="nota1">Nota 1</label>
                                    <b-form-textarea
                                        id="nota1"
                                        v-model="$v.consecutivo.nota1.$model"
                                        placeholder="Escriba su nota aquí..."
                                        :state="validateState('nota1')"
                                        rows="1"
                                        size="sm"
                                        :disabled="read_only"
                                    ></b-form-textarea>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.consecutivo.nota1.$anyError"
                                    >
                                        ({{
                                            $v.consecutivo.nota1.$model.length
                                        }}
                                        /
                                        {{
                                            $v.consecutivo.nota1.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.consecutivo.nota1.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.consecutivo.nota1.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="secuencia">Secuencia</label>
                                    <b-form-input
                                        id="secuencia"
                                        type="number"
                                        placeholder="Ej: 12345"
                                        v-model="
                                            $v.consecutivo.secuencia.$model
                                        "
                                        :state="validateState('secuencia')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.consecutivo.secuencia.$anyError
                                        "
                                    >
                                        ({{
                                            $v.consecutivo.secuencia.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.consecutivo.secuencia.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo.secuencia.maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.consecutivo.secuencia.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo.secuencia.required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="desde">Desde</label>
                                    <b-form-input
                                        id="desde"
                                        type="text"
                                        placeholder="Inicio secuencia"
                                        v-model="$v.consecutivo.desde.$model"
                                        :state="validateState('desde')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.consecutivo.desde.$anyError"
                                    >
                                        ({{
                                            $v.consecutivo.desde.$model.length
                                        }}
                                        /
                                        {{
                                            $v.consecutivo.desde.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.consecutivo.desde.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.consecutivo.desde.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.consecutivo.desde.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="hasta">Hasta</label>
                                    <b-form-input
                                        id="hasta"
                                        type="text"
                                        placeholder="Inicio secuencia"
                                        v-model="$v.consecutivo.hasta.$model"
                                        :state="validateState('hasta')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.consecutivo.hasta.$anyError"
                                    >
                                        ({{
                                            $v.consecutivo.hasta.$model.length
                                        }}
                                        /
                                        {{
                                            $v.consecutivo.hasta.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.consecutivo.hasta.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.consecutivo.hasta.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.consecutivo.hasta.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="d-flex mt-5">
                            <div>
                                <h3>
                                    Datos para el envío de facturas por correo
                                </h3>
                            </div>
                        </div>
                        <b-row class="mt-md-4">
                            <b-col cols="12" sm="6">
                                <b-form-group>
                                    <label for="configuracion_mail_id"
                                        >Email de envío de correo
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="configuracion_mail_id"
                                        :items="[configEmail]"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            configEmail !== null || read_only
                                        "
                                        v-model="
                                            $v.consecutivo.configuracion_mail_id
                                                .$model
                                        "
                                        :state="
                                            validateState(
                                                'configuracion_mail_id'
                                            )
                                        "
                                    />

                                    <!-- <b-form-select
                                        id="configuracion_mail_id"
                                        :options="[configEmail]"
                                        text-field="descripcion"
                                        value-field="id"
                                        :disabled="
                                            configEmail !== null || read_only
                                        "
                                        v-model="
                                            $v.consecutivo.configuracion_mail_id
                                                .$model
                                        "
                                        :state="
                                            validateState(
                                                'configuracion_mail_id'
                                            )
                                        "
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">
                                                Seleccione...
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.consecutivo.configuracion_mail_id
                                                .$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6">
                                <b-form-group>
                                    <label for="rg_plantilla_id"
                                        >Plantilla de documento
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="rg_plantilla_id"
                                        :items="plantillas"
                                        item-text="nombre"
                                        item-value="id"
                                        :disabled="read_only"
                                        open-on-focus
                                        v-model="
                                            $v.consecutivo.rg_plantilla_id
                                                .$model
                                        "
                                    />
                                    <!-- <b-form-select
                                        id="rg_plantilla_id"
                                        :options="plantillas"
                                        text-field="nombre"
                                        value-field="id"
                                        v-model="
                                            $v.consecutivo.rg_plantilla_id
                                                .$model
                                        "
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">
                                                Seleccione...
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select> -->
                                    <!-- <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo
                                                .configuracion_mail_id.required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback> -->
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="d-flex mt-5">
                            <div>
                                <h3>Configuración comprobante contable</h3>
                            </div>
                        </div>
                        <b-row class="mt-md-4">
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="comprobante_contable"
                                        >Comprobante Contable
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >

                                    <jautocomplete
                                        id="comprobante_contable"
                                        :items="comporbantesContables"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        v-model="
                                            $v.consecutivo.comprobante_contable
                                                .$model
                                        "
                                        :state="
                                            validateState(
                                                'comprobante_contable'
                                            )
                                        "
                                        :disabled="read_only"
                                    />
                                    <!-- <b-form-select
                                        id="comprobante_contable"
                                        :options="comporbantesContables"
                                        text-field="descripcion"
                                        value-field="id"
                                        v-model="
                                            $v.consecutivo.comprobante_contable
                                                .$model
                                        "
                                        :state="
                                            validateState(
                                                'comprobante_contable'
                                            )
                                        "
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">
                                                Seleccione...
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.consecutivo.comprobante_contable
                                                .$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <!-- <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="linea_lote"
                                        >Enlace
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-select
                                        id="linea_lote"
                                        :options="enlaces"
                                        text-field="descripcion"
                                        value-field="id"
                                        v-model="
                                            $v.consecutivo.linea_lote.$model
                                        "
                                        :state="validateState('linea_lote')"
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">
                                                Seleccione...
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.consecutivo.linea_lote.required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col> -->
                        </b-row>
                        <b-row class="mt-md-5">
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="electronica"
                                        >Documentro electrónico?</label
                                    >
                                    <b-form-checkbox
                                        id="electronica"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.consecutivo.electronica.$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="auto_print"
                                        >Imprimir consecutivo
                                        automáticamente?</label
                                    >
                                    <b-form-checkbox
                                        id="auto_print"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.consecutivo.auto_print.$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <!-- <b-form v-if="consecutivo.electronica">
                            <div class="mt-md-4">
                                <h3>Configuración comprobante contable</h3>
                            </div>
                            <b-row class="mt-md-4">
                                <b-col cols="12" md="6" lg="6" xl="6">
                                    <b-form-group>
                                        <label for="id_pin"
                                            >IDPIN
                                            <small style="color: red"
                                                >*</small
                                            ></label
                                        >
                                        <b-form-input
                                            id="id_pin"
                                            type="text"
                                            v-model="
                                                $v.consecutivo.id_pin.$model
                                            "
                                            :state="validateState('id_pin')"
                                            :disabled="read_only"
                                        >
                                        </b-form-input>
                                        <div
                                            class="length-field mt-1"
                                            v-if="
                                                !$v.consecutivo.id_pin.$anyError
                                            "
                                        >
                                            ({{
                                                $v.consecutivo.id_pin.$model
                                                    .length
                                            }}
                                            /
                                            {{
                                                $v.consecutivo.id_pin.$params
                                                    .maxLength.max
                                            }})
                                        </div>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.id_pin.maxLength
                                            "
                                        >
                                            Este campo debe tener máximo
                                            {{
                                                $v.consecutivo.id_pin.$params
                                                    .maxLength.max
                                            }}
                                            caracteres
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.id_pin.required
                                            "
                                        >
                                            Campo requerido
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6" lg="6" xl="6">
                                    <b-form-group>
                                        <label for="identif_software"
                                            >Identificación del software
                                            <small style="color: red"
                                                >*</small
                                            ></label
                                        >
                                        <b-form-input
                                            id="identif_software"
                                            type="text"
                                            v-model="
                                                $v.consecutivo.identif_software
                                                    .$model
                                            "
                                            :state="
                                                validateState(
                                                    'identif_software'
                                                )
                                            "
                                            :disabled="read_only"
                                        >
                                        </b-form-input>
                                        <div
                                            class="length-field mt-1"
                                            v-if="
                                                !$v.consecutivo.identif_software
                                                    .$anyError
                                            "
                                        >
                                            ({{
                                                $v.consecutivo.identif_software
                                                    .$model.length
                                            }}
                                            /
                                            {{
                                                $v.consecutivo.identif_software
                                                    .$params.maxLength.max
                                            }})
                                        </div>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.identif_software
                                                    .maxLength
                                            "
                                        >
                                            Este campo debe tener máximo
                                            {{
                                                $v.consecutivo.identif_software
                                                    .$params.maxLength.max
                                            }}
                                            caracteres
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.identif_software
                                                    .required
                                            "
                                        >
                                            Campo requerido
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-md-4">
                                <b-col cols="12" md="6" lg="6" xl="6">
                                    <b-form-group>
                                        <label for="clave_tecnica"
                                            >Clave Técnica
                                            <small style="color: red"
                                                >*</small
                                            ></label
                                        >
                                        <b-form-input
                                            id="clave_tecnica"
                                            type="text"
                                            v-model="
                                                $v.consecutivo.clave_tecnica
                                                    .$model
                                            "
                                            :state="
                                                validateState('clave_tecnica')
                                            "
                                            :disabled="read_only"
                                        >
                                        </b-form-input>
                                        <div
                                            class="length-field mt-1"
                                            v-if="
                                                !$v.consecutivo.clave_tecnica
                                                    .$anyError
                                            "
                                        >
                                            ({{
                                                $v.consecutivo.clave_tecnica
                                                    .$model.length
                                            }}
                                            /
                                            {{
                                                $v.consecutivo.clave_tecnica
                                                    .$params.maxLength.max
                                            }})
                                        </div>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.clave_tecnica
                                                    .maxLength
                                            "
                                        >
                                            Este campo debe tener máximo
                                            {{
                                                $v.consecutivo.clave_tecnica
                                                    .$params.maxLength.max
                                            }}
                                            caracteres
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.clave_tecnica
                                                    .required
                                            "
                                        >
                                            Campo requerido
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6" lg="6" xl="6">
                                    <b-form-group>
                                        <label for="test_set_id"
                                            >Test set id
                                            <small style="color: red"
                                                >*</small
                                            ></label
                                        >
                                        <b-form-input
                                            id="test_set_id"
                                            type="text"
                                            v-model="
                                                $v.consecutivo.test_set_id
                                                    .$model
                                            "
                                            :state="
                                                validateState('test_set_id')
                                            "
                                            :disabled="read_only"
                                        >
                                        </b-form-input>
                                        <div
                                            class="length-field mt-1"
                                            v-if="
                                                !$v.consecutivo.test_set_id
                                                    .$anyError
                                            "
                                        >
                                            ({{
                                                $v.consecutivo.test_set_id
                                                    .$model.length
                                            }}
                                            /
                                            {{
                                                $v.consecutivo.test_set_id
                                                    .$params.maxLength.max
                                            }})
                                        </div>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.test_set_id
                                                    .maxLength
                                            "
                                        >
                                            Este campo debe tener máximo
                                            {{
                                                $v.consecutivo.test_set_id
                                                    .$params.maxLength.max
                                            }}
                                            caracteres
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.test_set_id
                                                    .required
                                            "
                                        >
                                            Campo requerido
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-md-4">
                                <b-col cols="12" md="6" lg="6" xl="6">
                                    <b-form-group>
                                        <label for="xml_modelo"
                                            >Modelo XML
                                            <small style="color: red"
                                                >*</small
                                            ></label
                                        >
                                        <b-form-input
                                            id="xml_modelo"
                                            type="text"
                                            v-model="
                                                $v.consecutivo.xml_modelo.$model
                                            "
                                            :state="validateState('xml_modelo')"
                                            :disabled="read_only"
                                        >
                                        </b-form-input>
                                        <div
                                            class="length-field mt-1"
                                            v-if="
                                                !$v.consecutivo.xml_modelo
                                                    .$anyError
                                            "
                                        >
                                            ({{
                                                $v.consecutivo.xml_modelo.$model
                                                    .length
                                            }}
                                            /
                                            {{
                                                $v.consecutivo.xml_modelo
                                                    .$params.maxLength.max
                                            }})
                                        </div>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.xml_modelo
                                                    .maxLength
                                            "
                                        >
                                            Este campo debe tener máximo
                                            {{
                                                $v.consecutivo.xml_modelo
                                                    .$params.maxLength.max
                                            }}
                                            caracteres
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo.xml_modelo
                                                    .required
                                            "
                                        >
                                            Campo requerido
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6" lg="6" xl="6">
                                    <b-form-group>
                                        <label for="xml_modelo_detalle"
                                            >Modelo detalle XML
                                            <small style="color: red"
                                                >*</small
                                            ></label
                                        >
                                        <b-form-input
                                            id="xml_modelo_detalle"
                                            type="text"
                                            v-model="
                                                $v.consecutivo
                                                    .xml_modelo_detalle.$model
                                            "
                                            :state="
                                                validateState(
                                                    'xml_modelo_detalle'
                                                )
                                            "
                                            :disabled="read_only"
                                        >
                                        </b-form-input>
                                        <div
                                            class="length-field mt-1"
                                            v-if="
                                                !$v.consecutivo
                                                    .xml_modelo_detalle
                                                    .$anyError
                                            "
                                        >
                                            ({{
                                                $v.consecutivo
                                                    .xml_modelo_detalle.$model
                                                    .length
                                            }}
                                            /
                                            {{
                                                $v.consecutivo
                                                    .xml_modelo_detalle.$params
                                                    .maxLength.max
                                            }})
                                        </div>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo
                                                    .xml_modelo_detalle
                                                    .maxLength
                                            "
                                        >
                                            Este campo debe tener máximo
                                            {{
                                                $v.consecutivo
                                                    .xml_modelo_detalle.$params
                                                    .maxLength.max
                                            }}
                                            caracteres
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo
                                                    .xml_modelo_detalle.required
                                            "
                                        >
                                            Campo requerido
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mt-md-4">
                                <b-col cols="12">
                                    <b-form-group>
                                        <label for="xml_modelo_impuesto"
                                            >Modelo ipuestos XML
                                            <small style="color: red"
                                                >*</small
                                            ></label
                                        >
                                        <b-form-input
                                            id="xml_modelo_impuesto"
                                            type="text"
                                            v-model="
                                                $v.consecutivo
                                                    .xml_modelo_impuesto.$model
                                            "
                                            :state="
                                                validateState(
                                                    'xml_modelo_impuesto'
                                                )
                                            "
                                            :disabled="read_only"
                                        >
                                        </b-form-input>
                                        <div
                                            class="length-field mt-1"
                                            v-if="
                                                !$v.consecutivo
                                                    .xml_modelo_impuesto
                                                    .$anyError
                                            "
                                        >
                                            ({{
                                                $v.consecutivo
                                                    .xml_modelo_impuesto.$model
                                                    .length
                                            }}
                                            /
                                            {{
                                                $v.consecutivo
                                                    .xml_modelo_impuesto.$params
                                                    .maxLength.max
                                            }})
                                        </div>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo
                                                    .xml_modelo_impuesto
                                                    .maxLength
                                            "
                                        >
                                            Este campo debe tener máximo
                                            {{
                                                $v.consecutivo
                                                    .xml_modelo_impuesto.$params
                                                    .maxLength.max
                                            }}
                                            caracteres
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="
                                                !$v.consecutivo
                                                    .xml_modelo_impuesto
                                                    .required
                                            "
                                        >
                                            Campo requerido
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-form> -->
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
// import { compareDates } from '@/utils/date'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    goToNextField
} from '@/utils/others'
// import jautocomplete from '@/components/shared/autocomplete/jautocomplete'

const { API } = services

const defaultConsecutivo = () =>
    JSON.parse(
        JSON.stringify({
            clase_documento_id: null,
            descripcion: '',
            prefijo: '',
            nota1: '',
            secuencia: '',
            desde: '',
            hasta: '',
            configuracion_mail_id: null,
            comprobante_contable: null,
            linea_lote: 'LN',
            electronica: false,
            auto_print: false,
            sede_id: null,
            rg_plantilla_id: null
            // modalidad_facturacion_id: '',
            // tipo_solicitud_numeracion_id: null,
            // resolucion: '',
            // fecha_ini_resolucion: '',
            // fecha_fin_resolucion: '',
            // id_pin: '',
            // identif_software: '',
            // clave_tecnica: '',
            // test_set_id: '',
            // xml_modelo: '',
            // xml_modelo_detalle: '',
            // xml_modelo_impuesto: '',
        })
    )

export default {
    name: 'CrearConsecutivo',
    mixins: [validationMixin],
    components: { jautocomplete },
    data: () => ({
        clasesDocumento: [],
        // tiposSolicitud: [],
        // tipoModalidad: false,
        // tipoSolicitudYResolucion: false,
        // modalidadesFacturacion: [],
        configEmail: [],
        comporbantesContables: [],
        enlaces: [
            { descripcion: 'Línea', id: 'LN' },
            { descripcion: 'Lote', id: 'LT' }
        ],
        plantillas: [],
        consecutivoCopy: defaultConsecutivo(),
        consecutivo: defaultConsecutivo(),
        isLoading: false,
        isSaved: true,
        read_only: false
    }),
    watch: {
        consecutivo: {
            handler(newValue) {
                this.isSaved = compareObjects(this.consecutivoCopy, newValue)
            },
            deep: true
        }
    },
    async mounted() {
        this.goToNextField('clase_documento_id')
        addListenerCommands(this)
        await this.getConfigEmail()
        await this.getTemplates()
        await this.getComprobantesContables()
        await this.getClasesDocumento()
        // this.getModalidadesFacturacion()
        // this.getTiposSolicitud()
        await this.getParams()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        consecutivo: {
            clase_documento_id: {
                required
            },
            prefijo: {
                // maxLength: maxLength(20)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            // modalidad_facturacion_id: {
            //     required: requiredIf((model) => {
            //         return (
            //             model.clase_documento_id === 1 ||
            //             model.clase_documento_id === 2 ||
            //             model.clase_documento_id === 4 ||
            //             model.clase_documento_id === 5 ||
            //             model.clase_documento_id === 6 ||
            //             model.clase_documento_id === 9 ||
            //             model.clase_documento_id === 10
            //         )
            //     })
            // },
            // tipo_solicitud_numeracion_id: {
            //     required: requiredIf((model) => {
            //         return (
            //             model.modalidad_facturacion_id === 2 ||
            //             model.modalidad_facturacion_id === 3
            //         )
            //     })
            // },
            // resolucion: {
            //     required: requiredIf((model) => {
            //         return (
            //             model.modalidad_facturacion_id === 2 ||
            //             model.modalidad_facturacion_id === 3
            //         )
            //     }),
            //     maxLength: maxLength(50)
            // },
            // fecha_ini_resolucion: {
            //     required: requiredIf((model) => {
            //         return (
            //             model.modalidad_facturacion_id === 2 ||
            //             model.modalidad_facturacion_id === 3
            //         )
            //     }),
            //     compareDates: (d, a) => {
            //         if (a.fecha_fin_resolucion) {
            //             return compareDates(d, a.fecha_fin_resolucion)
            //         }
            //         return true
            //     },
            //     formatDate: (date) => {
            //         if (date) {
            //             const regExp =
            //                 /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
            //             const value = regExp.test(date)
            //             return value
            //         } else {
            //             return true
            //         }
            //     }
            // },
            // fecha_fin_resolucion: {
            //     required: requiredIf((model) => {
            //         console.log(
            //             model.modalidad_facturacion_id === 2 ||
            //                 model.modalidad_facturacion_id === 3
            //         )
            //         return (
            //             model.modalidad_facturacion_id === 2 ||
            //             model.modalidad_facturacion_id === 3
            //         )
            //     }),
            //     compareDates: (d, a) => {
            //         if (a.fecha_ini_resolucion) {
            //             return compareDates(a.fecha_ini_resolucion, d)
            //         }
            //         return true
            //     },
            //     formatDate: (date) => {
            //         if (date) {
            //             const regExp =
            //                 /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
            //             const value = regExp.test(date)
            //             return value
            //         } else {
            //             return true
            //         }
            //     }
            // },
            nota1: {
                maxLength: maxLength(500)
            },
            secuencia: {
                required,
                maxLength: maxLength(20)
            },
            desde: {
                required,
                maxLength: maxLength(20)
            },
            hasta: {
                required,
                maxLength: maxLength(20)
            },
            configuracion_mail_id: {
                required
            },
            comprobante_contable: {
                required
            },
            linea_lote: {
                required
            },
            electronica: {},
            auto_print: {},
            // id_pin: {
            //     maxLength: maxLength(100),
            //     required: requiredIf((model) => {
            //         return model.electronica
            //     })
            // },
            // identif_software: {
            //     maxLength: maxLength(100),
            //     required: requiredIf((model) => {
            //         return model.electronica
            //     })
            // },
            // clave_tecnica: {
            //     maxLength: maxLength(100),
            //     required: requiredIf((model) => {
            //         return model.electronica
            //     })
            // },
            // test_set_id: {
            //     maxLength: maxLength(100),
            //     required: requiredIf((model) => {
            //         return model.electronica
            //     })
            // },
            // xml_modelo: {
            //     maxLength: maxLength(150),
            //     required: requiredIf((model) => {
            //         return model.electronica
            //     })
            // },
            // xml_modelo_detalle: {
            //     maxLength: maxLength(150),
            //     required: requiredIf((model) => {
            //         return model.electronica
            //     })
            // },
            // xml_modelo_impuesto: {
            //     maxLength: maxLength(150),
            //     required: requiredIf((model) => {
            //         return model.electronica
            //     })
            // },
            rg_plantilla_id: {}
        }
    },
    methods: {
        goToNextField,
        showLog(data) {
            console.log(data)
        },
        async getParams() {
            const { consecutivo_id } = this.$route.params
            if (consecutivo_id) {
                await this.getConsecutivo(consecutivo_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        // onChangeClaseDocumento(value) {
        //     if (
        //         value === 1 ||
        //         value === 2 ||
        //         value === 4 ||
        //         value === 5 ||
        //         value === 6 ||
        //         value === 9 ||
        //         value === 10
        //     ) {
        //         this.consecutivo.modalidad_facturacion_id = ''
        //         this.tipoModalidad = true
        //     } else {
        //         this.consecutivo.modalidad_facturacion_id = 1 //'NOAPLICA'
        //         this.tipoModalidad = false
        //     }
        // },
        // onChangeModalidadFacturacion(value) {
        //     if (value === 2 || value === 3) {
        //         this.tipoSolicitudYResolucion = true
        //     } else {
        //         this.tipoSolicitudYResolucion = false
        //     }
        // },
        async getClasesDocumento() {
            await API.POST({
                url: 'administracion/clase-documento/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.clasesDocumento = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        async getComprobantesContables() {
            await API.POST({
                url: 'contabilidad/comprobante/query',
                data: {
                    p_datajson: { empresa_id: this.getUltimaEmpresa.id },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.comporbantesContables = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        // getModalidadesFacturacion() {
        //     API.POST({
        //         url: 'administracion/modalidad-facturacion/query',
        //         data: {
        //             p_datajson: {},
        //             p_opc: 'GET_ALL',
        //             p_auditoriajson: this.auditoriajson
        //         }
        //     })
        //         .then((response) => {
        //             const {
        //                 data: {
        //                     dato,
        //                     status: { status, mensaje, mensaje_exception }
        //                 }
        //             } = response
        //             if (status === 'ok') {
        //                 this.modalidadesFacturacion = dato
        //             } else {
        //                 this.$bvModal
        //                     .msgBoxOk(
        //                         mensaje ||
        //                             mensaje_exception ||
        //                             'Ha ocurrido un error!',
        //                         {
        //                             title: 'Error!',
        //                             size: 'sm',
        //                             buttonSize: 'sm',
        //                             okVariant: 'error',
        //                             okTitle: 'Aceptar',
        //                             cancelTitle: 'Cancelar',
        //                             footerClass: 'p-2',
        //                             hideHeaderClose: false,
        //                             centered: true,
        //                             headerBgVariant: 'error',
        //                             headerClass: 'modal-header'
        //                         }
        //                     )
        //                     .then(() => {
        //                         // console.log(value);
        //                     })
        //                     .catch((err) => {
        //                         console.log(err)
        //                     })
        //             }
        //         })
        //         .catch((error) => {
        //             const { message } = error
        //             this.$bvModal
        //                 .msgBoxOk(message || 'Ha ocurrido un error!', {
        //                     title: 'Error!',
        //                     size: 'sm',
        //                     buttonSize: 'sm',
        //                     okVariant: 'error',
        //                     okTitle: 'Aceptar',
        //                     cancelTitle: 'Cancelar',
        //                     footerClass: 'p-2',
        //                     hideHeaderClose: false,
        //                     centered: true,
        //                     headerBgVariant: 'error',
        //                     headerClass: 'modal-header'
        //                 })
        //                 .then(() => {
        //                     // console.log(value);
        //                 })
        //                 .catch((err) => {
        //                     console.log(err)
        //                 })
        //         })
        // },
        // getTiposSolicitud() {
        //     API.POST({
        //         url: 'administracion/tipo-solicitud/query',
        //         data: {
        //             p_datajson: {},
        //             p_opc: 'GET_ALL',
        //             p_auditoriajson: this.auditoriajson
        //         }
        //     })
        //         .then((response) => {
        //             const {
        //                 data: {
        //                     dato,
        //                     status: { status, mensaje, mensaje_exception }
        //                 }
        //             } = response
        //             if (status === 'ok') {
        //                 this.tiposSolicitud = dato
        //             } else {
        //                 this.$bvModal
        //                     .msgBoxOk(
        //                         mensaje ||
        //                             mensaje_exception ||
        //                             'Ha ocurrido un error!',
        //                         {
        //                             title: 'Error!',
        //                             size: 'sm',
        //                             buttonSize: 'sm',
        //                             okVariant: 'error',
        //                             okTitle: 'Aceptar',
        //                             cancelTitle: 'Cancelar',
        //                             footerClass: 'p-2',
        //                             hideHeaderClose: false,
        //                             centered: true,
        //                             headerBgVariant: 'error',
        //                             headerClass: 'modal-header'
        //                         }
        //                     )
        //                     .then(() => {
        //                         // console.log(value);
        //                     })
        //                     .catch((err) => {
        //                         console.log(err)
        //                     })
        //             }
        //         })
        //         .catch((error) => {
        //             const { message } = error
        //             this.$bvModal
        //                 .msgBoxOk(message || 'Ha ocurrido un error!', {
        //                     title: 'Error!',
        //                     size: 'sm',
        //                     buttonSize: 'sm',
        //                     okVariant: 'error',
        //                     okTitle: 'Aceptar',
        //                     cancelTitle: 'Cancelar',
        //                     footerClass: 'p-2',
        //                     hideHeaderClose: false,
        //                     centered: true,
        //                     headerBgVariant: 'error',
        //                     headerClass: 'modal-header'
        //                 })
        //                 .then(() => {
        //                     // console.log(value);
        //                 })
        //                 .catch((err) => {
        //                     console.log(err)
        //                 })
        //         })
        // },
        async getTemplates() {
            await API.POST({
                url: 'report/template/query',
                data: {
                    p_datajson: {
                        empresaId: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL_DOCUMENT',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.plantillas = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        async getConsecutivo(id) {
            // console.log('acá 2');
            await API.POST({
                url: 'administracion/consecutivo/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato)
                    if (status === 'ok') {
                        // this.onChangeModalidadFacturacion(
                        //     dato.modalidad_facturacion_id
                        // )
                        // this.onChangeClaseDocumento(dato.clase_documento_id)
                        console.log(dato, this.configEmail)
                        this.consecutivoCopy = { ...dato, configuracion_mail_id: Number(dato.configuracion_mail_id) }
                        this.consecutivo = { ...dato, configuracion_mail_id: Number(dato.configuracion_mail_id) }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        async getConfigEmail() {
            await API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.configEmail = dato[0]
                        if (dato) {
                            this.consecutivoCopy.configuracion_mail_id =
                                dato[0].id
                            this.consecutivo.configuracion_mail_id = dato[0].id
                        }
                    } else if (dato && status !== 'err') {
                        this.$bvModal
                            .msgBoxConfirm(
                                'Esta empresa no posee configuración de correo para reportes o envío de documentos por email, desea crear una configuración de correo para esta empresa?',
                                {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'warning',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'warning',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then((value) => {
                                if (value) {
                                    this.$router
                                        .push({
                                            path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                        })
                                        .catch((error) => error)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.consecutivo.$invalid
            if (valid) {
                this.isLoading = true
                const p_opc = this.consecutivo.id ? 'UPDATE' : 'INSERT'

                const consecutivo = JSON.parse(JSON.stringify(this.consecutivo))

                if (consecutivo.fecha_ini_resolucion) {
                    consecutivo.fecha_ini_resolucion = new Date(
                        consecutivo.fecha_ini_resolucion
                    )
                    consecutivo.fecha_fin_resolucion = new Date(
                        consecutivo.fecha_fin_resolucion
                    )
                }

                consecutivo.empresa_id = this.getUltimaEmpresa.id
                consecutivo.sede_id = this.getUltimaSede.id
                // console.log({
                //     url: 'administracion/consecutivo/crud',
                //     data: {
                //         p_datajson: {
                //             ...consecutivo
                //         },
                //         p_opc,
                //         p_auditoriajson: this.auditoriajson
                //     }
                // })
                API.POST({
                    url: 'administracion/consecutivo/crud',
                    data: {
                        p_datajson: {
                            ...consecutivo
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        // console.log(response.data)
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            this.back()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.consecutivo.$touch()
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.consecutivo[key]
            return $dirty ? !$error : null
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 2 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getUltimaSede'])
    }
}
</script>

<style></style>
